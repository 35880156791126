<template>
  <div>
    <b-overlay :show="isLoading">

      <b-alert
        show
        variant="info"
      >
        <div class="alert-body">
          <p>
            This list shows all policies and clients of all Avamar systems. You can use this list to check which data has not yet been entered in the reference list.
          </p>
          <p>
            Only policies and clients found within the last 30 days are used for this cross check.
          </p>
          <p>
            You can download the data formatted as a reference list, adjust just the weekdays you expect the backups and replications and upload it again.
          </p>

          <b-button
            size="sm"
            class="m-1"
            @click="downloadAsReferenceList"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-1"
            />
            <span class="align-middle">Download as reference list</span>
          </b-button>
        </div>
      </b-alert>

      <b-card no-title>
        <b-form
          inline
          class="mb-1"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="errorsOnly"
            >
              Errors only
            </b-form-checkbox>
          </b-form-group>
        </b-form>
      </b-card>

      <b-card
        v-if="systemsNotConfigured.length > 0"
        title="Systems not configured in reference list"
      >
        <ul>
          <li
            v-for="systemHostname in systemsNotConfigured"
            :key="systemHostname"
          >
            {{ systemHostname }}
          </li>
        </ul>
      </b-card>

      <b-card
        v-if="missingCombinations.length > 0"
        title="Missing Client-Group-Combination"
      >
        <div class="table-responsive">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>System</th>
                <th>Client</th>
                <th>Policy</th>
                <th>Cross check</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(crossCheck, index) in missingCombinations"
                :key="index"
              >
                <td>{{ crossCheck.hostname }}</td>
                <td>{{ crossCheck.client }}</td>
                <td>{{ crossCheck.group }}</td>
                <td>
                  <template v-if="!crossCheck.systemExistsInReferenceList">
                    System does not exists in reference list
                  </template>
                  <template v-else>
                    <template v-if="(!crossCheck.backupReferenceExists && crossCheck.backupReferenceShouldExists) || (!crossCheck.replicationReferenceExists && crossCheck.replicationReferenceShouldExists)">
                      <li v-if="!crossCheck.backupReferenceExists && crossCheck.backupReferenceShouldExists">
                        Client-Group-Combination does not exists in reference list for <strong><u>backup</u></strong>
                      </li>
                      <li v-if="!crossCheck.replicationReferenceExists && crossCheck.replicationReferenceShouldExists">
                        Client-Group-Combination does not exists in reference list for <strong><u>replication</u></strong>
                      </li>
                    </template>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import {
  BOverlay, BAlert, BButton, BCard, BForm, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import AvamarService from '@/service/avamar.service'

export default {
  components: {
    BOverlay,
    BAlert,
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormCheckbox,
  },
  data() {
    return {
      crossCheckItems: [],
      isLoading: false,
      errorsOnly: true,
    }
  },
  computed: {
    systemsNotConfigured() {
      const items = this.crossCheckItems.filter(x => x.systemExistsInReferenceList === false)
      return items.reduce((p, c) => {
        if (p.indexOf(c.hostname) === -1) {
          p.push(c.hostname)
        }

        return p
      }, [])
    },
    missingCombinations() {
      if (this.errorsOnly) {
        return this.crossCheckItems.filter(x => x.systemExistsInReferenceList === true && ((!x.backupReferenceExists && x.backupReferenceShouldExists) || (!x.replicationReferenceExists && x.replicationReferenceShouldExists)))
      }
      return this.crossCheckItems.filter(x => x.systemExistsInReferenceList === true)
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      AvamarService.getShouldRunReferenceCrossCheckListAsync()
        .then(result => {
          this.crossCheckItems = result
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    downloadAsReferenceList() {
      let csv = 'Type;Client;Policy;Node;Mon;Tue;Wed;Thu;Fri;Sat;Sun\n'
      this.crossCheckItems.forEach(crossCheck => {
        csv += `Backup;${crossCheck.client};${crossCheck.group};${crossCheck.hostname};0;0;0;0;0;0;0;\n`
        csv += `Replication;${crossCheck.client};${crossCheck.group};${crossCheck.hostname};0;0;0;0;0;0;0;\n`
      })

      const anchor = document.createElement('a')
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
      anchor.target = '_blank'
      anchor.download = 'avamar_referencelist.csv'
      anchor.click()
    },
  },
}

</script>
